import React from "react";
import SignInContainer from "@components/SignIn/SignInContainer";

// unAuthenticated page wrapper lives in SignInContainer
const Login = () => (
  <SignInContainer
    meta={{ title: "Login - Realworld" }}
    submitRedirect="/"
    authRedirect="/"
    createAccountLink="/signup"
    forgotPasswordLink="/forgot-password"
  />
);

export default Login;

Login.displayName = "Login";
